export default {
  RESET: 'RESET',
  STEP_1: 'STEP_1',
  RESET_STEP_1: 'RESET_STEP_1',
  STEP_2: 'STEP_2',
  RESET_STEP_2: 'RESET_STEP_2',
  STEP_3: 'STEP_3',
  RESET_STEP_3: 'RESET_STEP_3',
  STEP_4: 'STEP_4',
  RESET_STEP_4: 'RESET_STEP_4',
  STEP_5: 'STEP_5',
  RESET_STEP_5: 'RESET_STEP_5',
};
