import React from 'react';
import Header from 'components/header';

import styles from './styles.module.scss';

const TermsOfService = () => {
  return (
    <div className={styles.container}>
      <Header border />
      <div className={styles.contents}>
        <h2>Terms and condition for website</h2>
        <h3>Last Updated: March 22, 2022.</h3>
        <ul className={styles.listNumber}>
          <li>Introduction</li>
          <li>
            Welcome to bizflip, owned and operated by The Smile Guys, Inc. d/b/a
            bizflip (“bizflip,” “we,” “us”, or “our”). These Terms of Service
            (“Terms”) govern your access to and use of the Bizflip website(s),
            our APIs, mobile app (the “App”), and any other software, tools,
            features, or functionalities provided on or in connection with our
            services; including without limitation using our services to view,
            explore, and create NFTs and use our tools, at your own discretion,
            to connect directly with others to purchase, sell, or transfer NFTs
            on public blockchains (collectively, the “Service”). “NFT” in these
            Terms means a non-fungible token or similar digital item implemented
            on a blockchain (such as the Ethereum blockchain), which uses smart
            contracts to link to or otherwise be associated with certain content
            or data.
          </li>
          <li>
            For purposes of these Terms, “user”, “you”, and “your” means you
            user of the Service. If you use the Service on behalf of a company
            company or other entity then “you” includes you and that entity, and
            you represent and warrant that (a) you are an authorized
            representative of the entity with the authority to bind the entity
            to these Terms, and (b) you agree to these Terms on the entity’s
            behalf.
          </li>
          <li>
            PLEASE READ THESE TERMS OF SERVICE CAREFULLY AS THEY CONTAIN
            IMPORTANT INFORMATION AND AFFECT YOUR LEGAL RIGHTS. AS OUTLINED IN
            SECTION 16 BELOW, THEY INCLUDE A MANDATORY ARBITRATION AGREEMENT AND
            CLASS ACTION WAIVER WHICH (WITH LIMITED EXCEPTIONS) REQUIRE ANY
            DISPUTES BETWEEN US TO BE RESOLVED THROUGH INDIVIDUAL ARBITRATION
            RATHER THAN BY A JUDGE OR JURY IN COURT.
          </li>
          <li>
            BY CLICKING TO ACCEPT AND/OR USING OUR SERVICE, YOU AGREE TO BE
            BOUND BY THESE TERMS AND ALL OF THE TERMS INCORPORATED HEREIN BY
            REFERENCE. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS OR
            USE THE SERVICE.
          </li>
          <li>
            bizlfip is not a wallet provider as of this date, exchange,
            financial institution, or creditor. bizflip provides a peer-to-peer
            web3 service that helps users discover and directly interact with
            each other and NFTs available on public blockchains. We do not have
            custody or control over the NFTs or blockchains you are interacting
            with and we do not execute or effectuate purchases, transfers, or
            sales of NFTs. To use our Service, you must use a third-party wallet
            which allows you to engage in transactions on blockchains.
          </li>
          <li>
            bizflip is not party to any agreement between any users. You bear
            full responsibility for verifying the identity, legitimacy, and
            authenticity of NFTs that you purchase from third-party sellers
            using the Service and we make no claims about the identity,
            legitimacy, functionality, or authenticity of users or NFTs (and any
            content associated with such NFTs) visible on the Service.
          </li>
          <li>
            Because we have a growing number of services, we sometimes need
            provide additional terms for specific services (and such services
            are deemed part of the “Service” hereunder and shall also be subject
            to these Terms). Those additional terms and conditions, which are
            available with the relevant service, then become part of your
            agreement with us if you use those services. In the event of a
            conflict between these Terms and any additional applicable terms we
            may provide for a specific service, such additional terms shall
            control for that specific service.
          </li>
          <li>
            Bizflip reserves the right to change or modify these Terms at any
            time and in our sole discretion. If we make material changes to
            these Terms, we will use reasonable efforts to provide notice of
            such changes, such as by providing notice through the Service or
            updating the “Last Updated” date at the beginning of these Terms. By
            continuing to access or use the Service, you confirm your acceptance
            of the revised Terms and all of the terms incorporated therein by
            reference effective as of the date these Terms are updated. It is
            your sole responsibility to review the Terms from time to time to
            view such changes and to ensure that you understand the terms and
            conditions that apply when you access or use the Service.
          </li>
          <li>Accessing the Service </li>
          <li>
            Like much of web3, your blockchain address functions as your
            identity on bizflip. Accordingly, you will need a blockchain address
            and a third-party wallet to access the Service. Your account on the
            service (“Account”) will be associated with your blockchain address;
            however, if you want to add some flair and merit to your bizflip
            persona, you can add additional information, such as a profile
            picture, etc to your Account.
          </li>
          <li>
            Your Account on bizflip will be associated with your linked
            blockchain address and display the NFTs for that blockchain address
            (and, if applicable, any content associated with such NFTs). By
            using your wallet in connection with the Service, you agree that you
            are using that wallet under the terms and conditions of the
            applicable provider of the wallet. Wallets are not operated by,
            maintained by, or affiliated with bizflip, and bizflip does not have
            custody or control over the contents of your wallet and has no
            ability to retrieve or transfer its contents. Bizflip accepts no
            responsibility for, or liability to you, in connection with your use
            of a wallet and makes no representations or warranties regarding how
            the Service will operate with any specific wallet. You are solely
            responsible for keeping your wallet secure and you should never
            share your wallet credentials or seed phrase with anyone. If you
            discover an issue related to your wallet, please contact your wallet
            provider. Likewise, you are solely responsible for your Account and
            any associated wallet and we are not liable for any acts or
            omissions by you in connection with your Account or as a result of
            your Account or wallet being compromised. You agree to immediately
            notify us if you discover or otherwise suspect any security issues
            related to the Service or your Account.
          </li>
          <li>
            You also represent and warrant that you will comply with all
            applicable laws (e.g., local, state, federal and other laws) when
            using the Service. Without limiting the foregoing, by using the
            Service, you represent and warrant that: (a) you are not located in
            a country that is subject to a U.S. Government embargo; and (b) you
            have not been identified as a Specially Designated National or
            placed on any U.S. Government list of prohibited, sanctioned, or
            restricted parties. If you access or use the Service outside the
            United States, you are solely responsible for ensuring that your
            access and use of the Service in such country, territory or
            jurisdiction does not violate any applicable laws.
          </li>
          <li>
            bizflip may require you to provide additional information and
            documents in certain circumstances, such as at the request of any
            government authority, as any applicable law or regulation dictates,
            or to investigate a potential violation of these Terms. In such
            cases, bizflip, in its sole discretion, may disable your Account and
            block your ability to access the Service until such additional
            information and documents are processed by bizflip. If you do not
            provide complete and accurate information in response to such a
            request, bizflip may refuse to restore your access to the Service.
          </li>
          <li>
            Your access and use of the Service may be interrupted from time to
            time for any of several reasons, including, without limitation, the
            malfunction of equipment, periodic updating, maintenance, or repair
            of the Service or other actions that bizflip, in its sole
            discretion, may elect to take.
          </li>
          <li>
            We require all users to be at least 18 years old. If you are at
            least 13 years old but under 18 years old, you may only use bizflip
            through a parent or guardian’s Account and with their approval and
            oversight. That account holder is responsible for your actions using
            the Account. It is prohibited to use our Service if you are under 13
            years old.
          </li>
          <li>
            Ownership<br></br>The Service, including its “look and feel” (e.g.,
            text, graphics, images, logos, page headers, button icons, and
            scripts), proprietary content, information and other materials, and
            all content and other materials contained therein, including,
            without limitation, the Bizflip logo and all designs, text,
            graphics, pictures, data, software, sound files, other files, and
            the selection and arrangement thereof are the proprietary property
            of Bizflip or our affiliates, licensors, or users, as applicable,
            and you agree not to take any action(s) inconsistent with such
            ownership interests. We and our affiliates, licensors, and users, as
            applicable, reserve all rights in connection with the Service and
            its content, including, without limitation, the exclusive right to
            create derivative works.
          </li>
          <li>
            bizflip’s name, logo, trademarks, and any bizflip product or service
            names, designs, logos, and slogans are the intellectual property of
            Bizflip or our affiliates or licensors and may not be copied,
            imitated or used, in whole or in part, without our prior written
            permission in each instance. You may not use any metatags or other
            “hidden text” utilizing “Bizflip” or any other name, trademark or
            product or service name of  2. or our affiliates or licensors
            without our prior written permission. In addition, the “look and
            feel” of the Service constitutes the service mark, trademark or
            trade dress of bizflip and may not be copied, imitated or used, in
            whole or in part, without our prior written permission.
          </li>
          <li>
            All other third-party trademarks, registered trademarks, and product
            names mentioned on the Service or contained in the content linked to
            or associated with any NFTs displayed on the Service are the
            property of their respective owners and may not be copied, imitated
            or used, in whole or in part, without the permission of the
            applicable intellectual property rights holder. Reference to any
            products, services, processes or other information by name,
            trademark, manufacturer, supplier or otherwise does not constitute
            or imply endorsement, sponsorship, or recommendation by Bizflip.
          </li>
          <li>
            License to Access and Use Our Service and Content<br></br>You are
            hereby granted a limited, nonexclusive, nontransferable,
            nonsublicensable, and personal license to access and use the Service
            provided, however, that such license is subject to your compliance
            with these Terms. If any software, content, or other materials owned
            by, controlled by, or licensed to us are distributed or made
            available to you as part of your use of the Service, we hereby grant
            you a non-commercial, personal, non-assignable, non-sublicensable,
            non-transferrable, and non-exclusive right and license to access and
            display such software, content, and materials provided to you as
            part of the Service (and right to download a single copy of the App
            onto your applicable equipment or device), in each case for the sole
            purpose of enabling you to use the Service as permitted by these
            Terms, provided that your license in any content linked to or
            associated with any NFTs is solely as set forth by the applicable
            seller or creator of such NFT.
          </li>
          <li>
            Third-Party Content and Services<br></br>As a peer-to-peer web3
            service, bizflip helps you explore NFTs and/or Assets created by
            third parties and interact with different blockchains. Bizflip does
            not make any representations or warranties about this third-party
            content visible through our Service, including any content
            associated with NFTs displayed on the Service, and you bear
            responsibility for verifying the legitimacy, authenticity, and
            legality of NFTs that you purchase from third-party sellers. We also
            cannot guarantee that any NFTs visible on bizflip will always remain
            visible and/or available to be bought, sold, or transferred.
          </li>
          <li>
            NFTs may be subject to terms directly between buyers and sellers
            with respect to the use of the NFT content and benefits associated
            with a given NFT (“Purchase Terms”). For example, when you click to
            get more details about any of the NFTs visible on Bizflip, you may
            notice a third party link to the creator’s website. Such website may
            include Purchase Terms governing the use of the NFT that you will be
            required to comply with. Bizflip is not a party to any such Purchase
            Terms, which are solely between the buyer and the seller. The buyer
            and seller are entirely responsible for communicating, promulgating,
            agreeing to, and enforcing Purchase Terms. You are solely
            responsible for reviewing such Purchase Terms. <br></br>The Service
            may also contain links or functionality to access or use third-party
            websites (“Third-Party Websites”) and applications (“Third-Party
            Applications”), or otherwise display, include, or make available
            content, data, information, services, applications, or materials
            from third parties (“Third-Party Materials”). When you click on a
            link to, or access and use, a Third-Party Website or Third-Party
            Application, though we may not warn you that you have left our
            Service, you are subject to the terms and conditions (including
            privacy policies) of another website or destination. Such
            Third-Party Websites, Third-Party Applications, and Third-Party
            Materials are not under the control of Bizflip, and may be “open”
            applications for which no recourse is possible. Bizflip is not
            responsible or liable for any Third-Party Websites, Third-Party
            Applications, and Third-Party Materials. Bizflip provides links to
            these Third-Party Websites and Third-Party Applications only as a
            convenience and does not review, approve, monitor, endorse, warrant,
            or make any representations with respect to Third-Party Websites or
            Third-Party Applications, or their products or services or
            associated Third-Party Materials. You use all links in Third-Party
            Websites, Third-Party Applications, and Third-Party Materials at
            your own risk.
          </li>
          <li>
            User Conduct<br></br>Openness is one of our most prized values, and
            we’re committed to providing people from all walks of life and
            varying experience levels with web3 with a colorful lens into
            different blockchains. However, to protect our community and comply
            with our legal obligations, we reserve the right to take action,
            with or without advance notice, if we believe you have violated
            these Terms. This may include: removing the ability to view certain
            NFTs on the Service or use our Service to interact with the NFTs;
            disabling the ability to use the Service in conjunction with
            buying/selling/transferring NFTs available on blockchains; disabling
            your ability to access our Service; and/or other actions. <br></br>
            agree that you will not violate any law, contract, intellectual
            property or other third-party right, and that you are solely
            responsible for your conduct and content, while accessing or using
            the Service. You also agree that you will not:
          </li>
        </ul>
        <ul>
          <li>
            Use or attempt to use another user’s Account without authorization
            from such user;
          </li>
          <li>Pose as another person or entity;</li>
          <li>
            Claim an Bizflip username for the purpose of reselling it or
            otherwise engage in name squatting;
          </li>
          <li>
            Access the Service from a different blockchain address if we’ve
            blocked any of your other blockchain addresses from accessing the
            Service, unless you have our written permission first;
          </li>
          <li>
            Distribute spam, including through sending unwanted NFTs to other
            users;
          </li>
          <li>
            Use the Service – including through disseminating any software or
            interacting with any API – that could damage, disable, overburden,
            or impair the functioning of the Service in any manner;
          </li>
          <li>
            Bypass or ignore instructions that control access to the Service,
            including attempting to circumvent any rate limiting systems by
            using multiple API keys, directing traffic through multiple IP
            addresses, or otherwise obfuscating the source of traffic you send
            to Bizflip;
          </li>
          <li>
            Use any data mining, robot, spider, crawler, scraper, script,
            browser extension, offline reader, or other automated means or
            interface not authorized by us to access the Service, extract data,
            or otherwise interfere with or modify the rendering of Service pages
            or functionality;
          </li>
          <li>
            Reverse engineer, duplicate, decompile, disassemble, or decode any
            aspect of the Service, or do anything that might discover source
            code or bypass or circumvent measures employed to prevent or limit
            access to any service, area, or code of the Service;
          </li>
          <li>
            Sell or resell the Service or attempt to circumvent any Bizflip fee
            systems;
          </li>
          <li>
            Engage in behaviors that have the intention or the effect of
            artificially causing an item or collection to appear at the top of
            search results, or artificially increasing view counts, favorites,
            or other metrics that Bizflip might use to sort search results;
          </li>
          <li>
            Use the Service or data collected from our Service for any
            advertising or direct marketing activity (including without
            limitation, email marketing, SMS marketing, and telemarketing);
          </li>
          <li>
            Use the Service for money laundering, terrorist financing, or other
            illicit finance;
          </li>
          <li>
            Use the Service from a country sanctioned by the government of the
            United States or to facilitate transactions involving individuals
            sanctioned by the government of the United States or located in
            sanctioned countries;
          </li>
          <li>
            Use the Service to carry out any financial activities subject to
            registration or licensing, including but not limited to creating,
            selling, or buying securities, commodities, options, or debt
            instruments;
          </li>
          <li>
            Use the Service to create, sell, or buy NFTs or other items that
            give owners rights to participate in an ICO or any securities
            offering, or that are redeemable for securities, commodities, or
            other financial instruments;
          </li>
          <li>
            Use the Service to engage in price manipulation, fraud, or other
            deceptive, misleading, or manipulative activity;
          </li>
          <li>
            Use the Service to buy, sell, or transfer stolen items, fraudulently
            obtained items, items taken without authorization, and/or any other
            illegally obtained items;
          </li>
          <li>
            Infringe or violate the intellectual property rights or any other
            rights of others;
          </li>
          <li>
            Create or display illegal content, such as content that may involve
            child sexual exploitation;
          </li>
          <li>
            Create or display NFTs or other items that promote suicide or
            self-harm, incites hate or violence against others, or doxes another
            individual;
          </li>
          <li>
            Use the Service for any illegal or unauthorized purpose, including
            creating or displaying illegal content, such as content that may
            involve child sexual exploitation, or encouraging or promoting any
            activity that violates the Terms of Service;
          </li>
          <li>
            Use the Service in any manner that could interfere with, disrupt,
            negatively affect or inhibit other users from fully enjoying the
            Service.
          </li>
        </ul>
        <ul className={styles.listNumber}>
          <li>
            We allow users to post NSFW content, but that content and other
            associated material is subject to being marked NSFW and may be
            handled differently than non-NSFW content in navigation menus and
            search results.
          </li>
          <li>
            Finally, by using the Service, you understand the importance of DYOR
            – doing your own research. You bear full responsibility for
            verifying the authenticity, legitimacy, identity, and other details
            about any NFT, collection, or account that you view or otherwise
            interact with in conjunction with our Service. We make no guarantees
            or promises about the identity, legitimacy, or authenticity of any
            NFT, collection, or account on the Service.
          </li>
          <li>
            Intellectual Property Rights<br></br>You are solely responsible for
            your use of the Service and for any information you provide,
            including compliance with applicable laws, rules, and regulations,
            as well as these Terms, including the User Conduct requirements
            outlined above.
          </li>
          <li>
            By using the Service in conjunction with creating, submitting,
            posting, promoting, or displaying content, or by complying with
            Bizflip’s metadata standards in your metadata API responses, you
            grant us a worldwide, non-exclusive, sublicensable, royalty-free
            license to use, copy, modify, and display any content, including but
            not limited to text, materials, images, files, communications,
            comments, feedback, suggestions, ideas, concepts, questions, data,
            or otherwise, that you submit or post on or through the Service for
            our current and future business purposes, including to provide,
            promote, and improve the Service. This includes any digital file,
            art, or other material linked to or associated with any NFTs or
            assets that are displayed on or through the Service.
          </li>
          <li>
            Bizflip does not claim that submitting, posting, or displaying this
            content on or through the Service gives Bizflip any ownership of the
            content. We`re not saying we own it. We`re just saying we might use
            it and show it off a bit.
          </li>
          <li>
            You represent and warrant that you have, or have obtained, all
            rights, licenses, consents, permissions, power and/or authority
            necessary to grant the rights granted herein for any content that
            you create, submit, post, promote, or display on or through the
            Service. You represent and warrant that such content does not
            contain material subject to copyright, trademark, publicity rights,
            or other intellectual property rights, unless you have necessary
            permission or are otherwise legally entitled to post the material
            and to grant Bizflip the license described above, and that the
            content does not violate any laws.
          </li>
          <li>
            Bizflip will take down works in response to Digital Millennium
            Copyright Act (“DMCA”) takedown notices and/or other intellectual
            property infringement claims and will terminate a user`s access to
            the Service if the user is determined to be a repeat infringer. If
            you believe that your content has been copied in a way that
            constitutes copyright or trademark infringement, or violates your
            publicity or other intellectual property rights, please contact us
            by email or you may submit written notice to our designated
            copyright agent at:
          </li>
          <li>
            The Smile Guys, Inc. <br></br>1. Attn: Legal Department<br></br>PO
            Box 271,<br></br>Indian trail, NC 28107<br></br>Email:
            support@bizflip.io
          </li>
          <li>
            For us to process your infringement claim regarding content on the
            Service, you must be the rightsholder or someone authorized to act
            on behalf of the rightsholder. We encourage you to use official
            forms to help ensure the requisite information is included in your
            notice. If you choose to write to us by e-mail or physical mail
            instead, your notice must include: <br></br>
            <ul>
              <li>
                Identification of the copyrighted work(s), trademark,
                intellectual property rights that you claim is claim is claim is
                claim is being infringed;
              </li>
              <li>
                Identification of the allegedly infringing material that is
                requested to be removed, including a description of the specific
                location (i.e., urls) on the Service of the material claimed to
                be infringing, so that we may locate the material;
              </li>
              <li>
                Your contact information – at a minimum, your full legal name
                (not pseudonym) and email address;
              </li>
              <li>
                A declaration that contains all of the following:<br></br>A
                statement that you have a good faith belief that use of the
                material in the manner complained of is not authorized by the
                intellectual property rights owner, its agent, or the law;
              </li>
              <li>
                A statement that the information in the notice is accurate; and
              </li>
              <li>
                A statement under penalty of perjury that you are authorized to
                act on behalf of the intellectual property owner of the
                intellectual property that is allegedly being infringed.
              </li>
              <li>
                Your physical or electronic signature (of your full legal name).
              </li>
            </ul>
          </li>
          <li>
            Please note that we will forward your notice of intellectual
            property infringement, including your contact information, to the
            party who will have their content removed so they understand why it
            is no longer available on Bizflip and can also contact you to
            resolve any dispute.
          </li>
          <li>
            Communication Preferences<br></br>By creating an Account, you
            consent to receive electronic communications from Bizflip (e.g., via
            via email, push notification, text messages, or other types of
            messages). These communications may include notices about your
            Account (e.g., transactional information) and are part of your
            relationship with us. We may also send you promotional
            communications via email we think will be of interest to you. You
            You understand that you are not required to provide this consent as
            a condition of using the Service and you may opt out of these
            communications through the Service or through your mobile device’s
            operating system (with the possible exception of important service
            announcements and administrative messages) by following the
            unsubscribe instructions provided.
          </li>
          <li>
            App Terms<br></br>You are responsible for providing the mobile
            device, wireless service plan, software, Internet connections,
            and/or other equipment or services that you need to download,
            install, and use the App. We do not guarantee that the App can be
            accessed and used on any particular device or with any particular
            particular service plan. We do not guarantee that the App or Service
            will be available in any particular geographic location.
          </li>
          <li>
            The following terms and conditions apply to you only if you are
            using the App from the Apple App Store: To the extent the other
            terms and conditions of these Terms are less restrictive than, or
            otherwise conflict with, the terms and conditions of this paragraph,
            the more restrictive or conflicting terms and conditions in this
            paragraph apply, but solely with respect to your use of the App from
            the Apple App Store. You acknowledge and agree that these Terms are
            solely between you and Bizflip, not Apple, and that Apple has no
            responsibility for the App or content thereof. Your use of the App
            must comply with the App Store’s applicable terms of use. You
            acknowledge that Apple has no obligation whatsoever to furnish any
            maintenance and support services with respect to the App. In the
            event of any failure of the App to conform to any applicable
            warranty, you may notify Apple, and Apple will refund the purchase
            price, if any, for the App to you. To the maximum extent permitted
            by applicable law, Apple will have no other warranty obligation
            whatsoever with respect to the App, and any other claims, losses,
            damages, costs or expenses attributable to any failure to conform to
            any warranty will be solely governed by these Terms. You acknowledge
            that Apple is not responsible for addressing any claims of yours or
            any third party relating to the App or your possession and/or use of
            the App, including, but not limited to: (a) product liability
            claims, (b) any claim that the App fails to conform to any
            applicable legal or regulatory requirement, and (c) claims arising
            under consumer protection or similar legislation. You acknowledge
            that, in the event of any third-party claim that the App or your
            possession and use of that App infringes that third party’s
            intellectual property rights, Bizflip, not Apple, will be solely
            responsible for the investigation, defense, settlement and discharge
            intellectual property infringement claim to the extent required by
            these Terms. You must comply with applicable third-party terms of
            agreement when using the App. You acknowledge and agree and Apple’s
            subsidiaries, are third-party beneficiaries of these Terms as they
            relate to your use of the App, and that, upon your acceptance of
            these Terms, Apple will have the right (and will be deemed to have
            accepted the right) to enforce these Terms against you as a
            third-party beneficiary thereof.
          </li>
          <li>
            Indemnification<br></br>By agreeing to these Terms and accessing the
            Service, you agree, to the fullest extent permitted by applicable
            law, to indemnify, defend, and hold harmless Bizflip, and our
            respective past, present, and future employees, officers, directors,
            contractors, consultants, equity holders, suppliers, vendors,
            service providers, parent companies, subsidiaries, affiliates,
            agents, representatives, predecessors, successors, and assigns
            (individually and collectively, the “Bizflip Parties”), from and
            against all actual or alleged claims, damages, awards, judgments,
            losses, liabilities, obligations, penalties, interest, fees,
            expenses (including, without limitation, attorneys’ fees and
            expenses), and costs (including, without limitation, court costs,
            costs of settlement, and costs of pursuing indemnification and
            insurance), of every kind and nature whatsoever, whether known or
            unknown, foreseen or unforeseen, matured or unmatured, or suspected
            or unsuspected, in law or equity, whether in tort, contract, or
            otherwise (collectively, “Claims”), including, but not limited to,
            damages to property or personal injury, that are caused by, arise
            out of or are related to (a) your use or misuse of the Service,
            content, NFTs, Assets or content linked to or associated with any
            NFTs (b) any Feedback you provide, (c) your violation or breach of
            any term of these Terms or applicable law, and (d) your violation of
            the rights of or obligations to a third party, including another
            user or third-party, and (e) your negligence or wilful misconduct.
            You agree to promptly notify Bizflip of any Claims and cooperate
            with the Bizflip Parties in defending such Claims. You further agree
            that the Bizflip Parties shall have control of the defense or
            settlement of any Claims. THIS INDEMNITY IS IN ADDITION TO, AND NOT
            IN LIEU OF, ANY OTHER INDEMNITIES SET FORTH IN A WRITTEN AGREEMENT
            BETWEEN YOU AND BIZFLIP.
          </li>
          <li>
            Disclaimers<br></br>YOUR ACCESS TO AND USE OF THE SERVICE IS AT YOUR
            OWN RISK. YOU UNDERSTAND AND AGREE THAT THE SERVICE IS PROVIDED ON
            AN “AS IS” AND “AS AVAILABLE” BASIS AND BIZFLIP EXPRESSLY DISCLAIMS
            WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED.
            BIZFLIP(AND ITS SUPPLIERS) MAKE NO WARRANTY OR REPRESENTATION AND
            DISCLAIM ALL RESPONSIBILITY FOR WHETHER THE SERVICE: (A) WILL MEET
            YOUR REQUIREMENTS; (B) WILL BE AVAILABLE ON AN UNINTERRUPTED,
            TIMELY, SECURE, OR ERROR-FREE BASIS; OR (C) WILL BE ACCURATE,
            RELIABLE, COMPLETE, LEGAL, OR SAFE. BIZFLIP DISCLAIMS ALL OTHER
            WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED, INCLUDING, WITHOUT
            LIMITATION, IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
            BIZFLIP WILL NOT BE LIABLE FOR ANY LOSS OF ANY KIND FROM ANY ACTION
            TAKEN OR TAKEN IN RELIANCE ON MATERIAL OR INFORMATION, CONTAINED ON
            THE SERVICE. WHILE BIZFLIP ATTEMPTS TO MAKE YOUR ACCESS TO AND USE
            OF THE SERVICE SAFE, BIZFLIP CANNOT AND DOES NOT REPRESENT OR
            WARRANT THAT THE SERVICE, CONTENT, CONTENT LINKED TO OR ASSOCIATED
            WITH ANY NFTS, ASSETS, PURCHASABLE ITEMS OR ANY NFTS YOU INTERACT
            WITH USING OUR SERVICE OR OUR SERVICE PROVIDERS’ SERVERS ARE FREE OF
            VIRUSES OR OTHER HARMFUL COMPONENTS. WE CANNOT GUARANTEE THE
            SECURITY OF ANY DATA THAT YOU DISCLOSE ONLINE. NO ADVICE OR
            INFORMATION, WHETHER ORAL OR OBTAINED FROM THE BIZFLIP PARTIES OR
            THROUGH THE SERVICE, WILL CREATE ANY WARRANTY OR REPRESENTATION NOT
            EXPRESSLY MADE HEREIN. YOU ACCEPT THE INHERENT SECURITY RISKS OF
            PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET AND WILL
            NOT HOLD BIZFLIP RESPONSIBLE FOR ANY BREACH OF SECURITY.
          </li>
          <li>
            WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO
            RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY USE OF
            NFTS, ASSETS, CONTENT, AND/OR CONTENT LINKED TO OR ASSOCIATED WITH
            NFTS, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES, OR CLAIMS
            ARISING FROM: (A) USER ERROR, INCORRECTLY CONSTRUCTED TRANSACTIONS,
            OR MISTYPED ADDRESSES; (B) SERVER FAILURE OR DATA LOSS; (C)
            UNAUTHORIZED ACCESS OR USE; (D) ANY UNAUTHORIZED THIRD-PARTY
            ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES,
            PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST THE SERVICE
            OR NFTS.
          </li>
          <li>
            NFTS EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE
            ASSOCIATED BLOCKCHAIN (E.G., ETHEREUM NETWORK). ANY TRANSFERS OR
            SALES OCCUR ON THE ASSOCIATED BLOCKCHAIN (E.G., ETHEREUM). BIZFLIP
            AND/OR ANY OTHER BIZFLIP PARTY CANNOT EFFECT OR OTHERWISE CONTROL
            THE TRANSFER OF TITLE OR RIGHT IN ANY NFTS OR UNDERLYING OR
            ASSOCIATED CONTENT OR ITEMS.
          </li>
          <li>
            NO BIZFLIP PARTY IS RESPONSIBLE OR LIABLE FOR ANY SUSTAINED LOSSES
            OR INJURY DUE TO VULNERABILITY OR ANY KIND OF FAILURE, ABNORMAL
            BEHAVIOR OF SOFTWARE (E.G., WALLET, SMART CONTRACT), BLOCKCHAINS OR
            ANY OTHER FEATURES OF THE NFTS. NO BIZFLIP PARTY IS RESPONSIBLE FOR
            LOSSES OR INJURY DUE TO LATE REPORTS BY DEVELOPERS OR
            REPRESENTATIVES (OR NO REPORT AT ALL) OF ANY ISSUES WITH THE
            BLOCKCHAIN SUPPORTING THE NFTS, INCLUDING FORKS, TECHNICAL NODE
            ISSUES OR ANY OTHER ISSUES HAVING LOSSES OR INJURY AS A RESULT.
          </li>
          <li>
            Some jurisdictions do not allow the exclusion of implied warranties
            in contracts with consumers, so the above exclusion may not apply to
            you.
          </li>
          <li>Assumption of Risk</li>
          <li>
            You accept and acknowledge:<br></br>
            <ul>
              <li>
                The value of an NFTs is subjective. Prices of NFTs are subject
                to volatility and fluctuations in the price of cryptocurrency
                can also materially and adversely affect NFT prices. You
                acknowledge that you fully understand this subjectivity and
                volatility and that you may lose money.
              </li>
              <li>
                A lack of use or public interest in the creation and development
                of distributed ecosystems could negatively impact the
                development of those ecosystems and related applications, and
                could therefore also negatively impact the potential utility of
                NFTs.
              </li>
              <li>
                The regulatory regime governing blockchain technologies,
                non-fungible tokens, cryptocurrency, and other crypto-based
                items is uncertain, and new regulations or policies may
                materially adversely affect the development of the Service and
                the utility of NFTs.
              </li>
              <li>
                You are solely responsible for determining what, if any, taxes
                apply to your transactions. Bizflip is not responsible for
                determining the taxes that apply to your NFTs.
              </li>
              <li>
                There are risks associated with purchasing items associated with
                content created by third parties through peer-to-peer
                transactions, including but not limited to, the risk of
                purchasing counterfeit items, mislabeled items, items that are
                vulnerable to metadata decay, items on smart contracts with
                bugs, and items that may become untransferable. You represent
                and warrant that you have done sufficient research before making
                any decisions to sell, obtain, transfer, or otherwise interact
                with any NFTs or accounts/collections.
              </li>
              <li>
                We do not control the public blockchains that you are
                interacting with and we do not control certain smart contracts
                and protocols that may be integral to your ability to complete
                transactions on these public blockchains. Additionally,
                blockchain transactions are irreversible and bizflip has no
                ability to reverse any transactions on the blockchain.
              </li>
              <li>
                There are risks associated with using Internet and blockchain
                based products, including, but not limited to, the risk
                associated with hardware, software, and Internet connections,
                the risk of malicious software introduction, and the risk that
                third parties may obtain unauthorized access to your third-party
                wallet or Account. You accept and acknowledge that bizflip will
                not be responsible for any communication failures, disruptions,
                errors, distortions or delays you may experience when using the
                Service or any Blockchain network, however caused.
              </li>
              <li>
                The Service relies on third-party platforms and/or vendors. If
                we are unable to maintain a good relationship with such platform
                providers and/or vendors; if the terms and conditions or pricing
                of such platform providers and/or vendors change; if we violate
                or cannot comply with the terms and conditions of such platforms
                and/or vendors; or if any of such platforms and/or vendors loses
                market share or falls out of favor or is unavailable for a
                prolonged period of time, access to and use of the Service will
                suffer.
              </li>
              <li>
                bizflip reserves the right to hide collections, contracts, and
                items affected by any of these issues or by other issues. Items
                you purchase may become inaccessible on bizflip. Under no
                circumstances shall the inability to view items on bizflip or an
                inability to use the Service in conjunction with the purchase,
                sale, or transfer of items available on any blockchains serve as
                grounds for a claim against bizflip.
              </li>
              <li>
                If you have a dispute with one or more users, YOU RELEASE US
                FROM CLAIMS, DEMANDS, AND DAMAGES OF EVERY KIND AND NATURE,
                KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH
                SUCH DISPUTES. IN ENTERING INTO THIS RELEASE YOU EXPRESSLY WAIVE
                ANY PROTECTIONS (WHETHER STATUTORY OR OTHERWISE) THAT WOULD
                OTHERWISE LIMIT THE COVERAGE OF THIS RELEASE TO INCLUDE THOSE
                CLAIMS WHICH YOU MAY KNOW OR SUSPECT TO EXIST IN YOUR FAVOR AT
                THE TIME OF AGREEING TO THIS RELEASE.
              </li>
            </ul>
          </li>
          <li>
            Limitation of Liability<br></br>TO THE FULLEST EXTENT PERMITTED BY
            LAW, YOU AGREE THAT IN NO EVENT WILL BIZFLIP OR ITS SERVICE
            PROVIDERS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR
            ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR
            PUNITIVE DAMAGES ARISING FROM THESE TERMS OR THE SERVICE, PRODUCTS
            OR THIRD-PARTY SITES AND PRODUCTS, OR FOR ANY DAMAGES RELATED TO
            LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
            SAVINGS, LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF DATA, AND WHETHER
            CAUSED BY STRICT LIABILITY OR TORT (INCLUDING NEGLIGENCE), BREACH OF
            CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE AND EVEN IF BIZFLIP OR
            ITS SERVICE PROVIDERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES; OR (B) FOR ANY OTHER CLAIM, DEMAND, OR DAMAGES WHATSOEVER
            RESULTING FROM OR ARISING OUT OF OR IN CONNECTION WITH THESE TERMS
            OF THE DELIVERY, USE, OR PERFORMANCE OF THE SERVICE. ACCESS TO, AND
            USE OF, THE SERVICE, PRODUCTS OR THIRD-PARTY SITES, AND PRODUCTS ARE
            AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE
            FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF
            DATA RESULTING THEREFROM.
          </li>
          <li>
            NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO
            EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF BIZFLIP ARISING OUT
            OF OR IN ANY WAY RELATED TO THESE TERMS, THE ACCESS TO AND USE OF
            THE SERVICE, CONTENT, NFTS, OR ANY BIZFLIP PRODUCTS OR SERVICES
            EXCEED THE GREATER OF (A) $100 OR (B) THE AMOUNT RECEIVED BY BIZFLIP
            FOR ITS SERVICE DIRECTLY RELATING TO THE ITEMS THAT ARE THE SUBJECT
            OF THE CLAIM. THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE
            STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
          </li>
          <li>
            Some jurisdictions do not allow the exclusion or limitation of
            incidental or consequential damages, so the above limitation or
            exclusion may not apply to you. Some jurisdictions also limit
            disclaimers or limitations of liability for personal injury from
            consumer products, so this limitation may not apply to personal
            injury claims.
          </li>
          <li>
            Privacy Policy<br></br>Please refer to our privacy policy for
            information about how we collect, use, and share personal data about
            you. By submitting personal data through our Service, you agree to
            the terms of our privacy policy and you expressly consent to the
            collection, use, and disclosure of your personal data in accordance
            with the privacy policy.
          </li>
          <li>
            Modifications to the Service<br></br>We reserve the right in our
            sole discretion to modify, suspend, or discontinue, temporarily or
            permanently, the Service (or any features or parts thereof) at any
            time and without liability as a result.
            <ul>
              <li>
                Dispute Resolution; ArbitrationDispute Resolution. Please read
                the following arbitration agreement in this Section
                (“Arbitration Agreement”) carefully. It requires you to
                arbitrate disputes with Bizflip and limits the manner in which
                you can seek relief from us. This section does not govern
                disputes between users or between users and third parties..
                Bizflip does not provide dispute resolution services for such
                disagreements and the parties must resolve those disputes
                directly.
              </li>
              <li>
                Applicability of Arbitration Agreement. You agree that any
                dispute, controversy, or claim relating in any way to your
                access or use of the Service, to any products sold or
                distributed through the Service, or to any aspect of your
                relationship with Bizflip, will be resolved by binding
                arbitration, rather than in court, including threshold questions
                of the arbitrability of such dispute, controversy, or claim
                except that (1) you or Bizflip may assert claims in small claims
                court, but only if the claims qualify, the claims remain only in
                such court, and the claims remain on an individual,
                non-representative, and non-class basis; and (2) you or bizflip
                may seek injunctive or equitable relief in a court of proper
                jurisdiction if the claim relates to intellectual property
                infringement or other misuse of intellectual property rights.
              </li>
              <li>
                Dispute resolution process. You and Bizflip both agree to engage
                in good-faith efforts to resolve disputes prior to either party
                initiating an arbitration, small claims court proceeding, or
                equitable relief for intellectual property infringement. You
                must initiate this dispute resolution process by sending a
                letter describing the nature of your claim and desired
                resolution to: Bizflip, Attn: Legal Department, 271 PO Box
                indian trail, nc 28107. Both parties agree to meet and confer
                personally, by telephone, or by videoconference (hereinafter
                “Conference”) to discuss the dispute and attempt in good faith
                to reach a mutually beneficial outcome that avoids the expenses
                of arbitration or, where applicable, litigation. If you are
                represented by counsel, your counsel may participate in the
                Conference as well, but you agree to fully participate in the
                Conference. Likewise, if Bizflip is represented by counsel, its
                counsel may participate in the Conference as well, but Bizflip
                agrees to have a company representative fully participate in the
                Conference. The statute of limitations and any filing fee
                deadlines shall be tolled while the parties engage in the
                informal dispute resolution process and Conference required by
                this paragraph. If the parties do not reach agreement to resolve
                the dispute within thirty (30) days after initiation of this
                dispute resolution process, either party may commence
                arbitration, file an action in small claims court, or file a
                claim for injunctive or equitable relief in a court of proper
                jurisdiction for matters relating to intellectual property
                infringement, if the claims qualify.
              </li>
              <li>
                Arbitration Rules and Forum. The Federal Arbitration Act governs
                the interpretation and enforcement of this Arbitration
                Agreement. To begin an arbitration proceeding after
                participating in the dispute resolution process, you must send a
                letter requesting arbitration and describing your claim to our
                registered agent at Bizflip, Attn: Legal Department, 271 PO Box
                indian trail, nc 28107. The arbitration will be conducted by
                JAMS, an established alternative dispute resolution provider.
                Disputes involving claims and counterclaims under $250,000, not
                inclusive of attorneys’ fees and interest, shall be subject to
                JAMS’s most current version of the Streamlined Arbitration Rules
                and the JAMS Consumer Minimum Standards then in effect; all
                other claims shall be subject to JAMS’s most current version of
                the Comprehensive Arbitration Rules and Procedures and the JAMS
                Consumer Minimum Standards then in effect. JAMS’s rules are
                available at jamsadr.com or by calling JAMS at 800-352- 5267. If
                JAMS is not available to arbitrate, the parties will select an
                alternative arbitral forum. If the arbitrator finds that you
                cannot afford to pay JAMS filing, administrative, hearing,
                and/or other fees and cannot obtain a waiver from JAMS, Bizflip
                will pay them for you if you complied with the dispute
                resolution process set forth above. In addition, Bizflip will
                reimburse all such JAMS filing, administrative, hearing, and/or
                other fees for claims totaling less than $10,000 unless the
                arbitrator determines the claims are frivolous or you did not
                comply with the dispute resolution process set forth above,
                except that if you have initiated the arbitration claim, you
                will still be required to pay the lesser of $250 or the maximum
                amount permitted under the JAMS Rules for arbitration claims
                initiated by you. You are still responsible for all additional
                costs that you incur in the arbitration, including without
                limitation, fees for attorneys or expert witnesses. You may
                choose to have the arbitration conducted by telephone or
                videoconference, based on written submissions, in person in your
                hometown area (if you live in the United States), or at another
                mutually agreed upon location that is reasonably convenient to
                you. Any judgment on the award rendered by the arbitrator may be
                entered in any court of competent jurisdiction.
              </li>
              <li>
                Authority of Arbitrator. The arbitrator shall have exclusive
                authority to (a) determine the scope and enforceability of this
                Arbitration Agreement and (b) resolve any dispute related to the
                interpretation, applicability, enforceability, or formation of
                this Arbitration Agreement including, but not limited to, any
                claim that all or any part of this Arbitration Agreement is void
                or voidable. The arbitration will decide the rights and
                liabilities, if any, of you and Bizflip. The arbitration
                proceeding will not be consolidated with any other matters or
                joined with any other cases or parties. The arbitrator shall
                have the authority to grant motions dispositive of all or part
                of any claim. The arbitrator shall have the authority to award
                monetary damages and to grant any non-monetary remedy or relief
                available to an individual under applicable law, the arbitral
                forum’s rules, and these Terms. The arbitrator shall issue a
                written award and statement of decision describing the essential
                findings and conclusions on which the award is based, including
                the calculation of any damages awarded. The arbitrator has the
                same authority to award relief on an individual basis that a
                judge in a court of law would have. The award of the arbitrator
                is final and binding upon you and us.
              </li>
              <li>
                Waiver of Jury Trial. YOU AND BIZFLIP HEREBY WAIVE ANY
                CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND HAVE A
                TRIAL IN FRONT OF A JUDGE OR A JURY. You and Bizflip are instead
                electing that all claims and disputes shall be resolved by
                arbitration under this Arbitration Agreement, except as
                specified in the second bullet of this Section 16, above
                (“Applicability of Arbitration Agreement”). An arbitrator can
                award on an individual basis the same damages and relief as a
                court and must follow these Terms as a court would. However,
                there is no judge or jury in arbitration, and court review of an
                arbitration award is subject to very limited review.
              </li>
              <li>
                Waiver of Class Actions and Class Arbitrations. ALL CLAIMS AND
                DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE
                ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A REPRESENTATIVE OR
                COLLECTIVE CLASS BASIS. ONLY INDIVIDUAL RELIEF IS AVAILABLE, AND
                CLAIMS OF MORE THAN ONE USER, PERSON, OR ENTITY CANNOT BE
                ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER USER, PERSON,
                OR ENTITY. Accordingly, under the arbitration procedures
                outlined in this section, an arbitrator shall not combine or
                consolidate more than one party’s claims without the written
                consent of all affected parties to an arbitration proceeding.
                Without limiting the generality of the foregoing, you and
                Bizflip agree that no dispute shall proceed by way of class
                arbitration without the written consent of all affected parties.
                If a decision is issued stating that applicable law precludes
                enforcement of any part of this subsection’s limitations as to a
                given claim for relief, then that claim must be severed from the
                arbitration and brought in the state or federal courts located
                in the state of North Carolina. All other claims shall be
                arbitrated.
              </li>
              <li>
                Severability. Except as provided in this Section, if any part or
                parts of this Arbitration Agreement are found under the law to
                be invalid or unenforceable, then such specific part or parts
                shall be of no force and effect and shall be severed and the
                remainder of the Arbitration Agreement shall continue in full
                force and effect.
              </li>
              <li>
                Survival of Agreement. This Arbitration Agreement will survive
                the termination of your relationship with Bizflip.
              </li>
            </ul>
          </li>
          <li>
            Modification. <br></br>Notwithstanding any provision in these to the
            to the contrary, we agree that if Bizflip Bizflip makes any future
            change to this Arbitration Agreement, you within within within
            within thirty (30) days of such change becoming effective by writing
            writing to Bizflip at the following address: Bizlfip, Attn: Legal
            Department,271 PO Box indian trail, nc 28107Governing Law and Venue
          </li>
          <li>
            These Terms and your access to and use of the Service shall be
            governed by and construed and enforced in accordance with the laws
            of the State of New York (without regard to conflict of law rules
            principles of the State of Delaware or North Carolina, or any other
            other jurisdiction that would cause the application of the laws of
            any other jurisdiction). Any dispute between the parties that is
            subject to arbitration as set forth in Section 16 or cannot be heard
            in small claims court, shall be resolved in the state or federal
            courts of the state of Delaware and/or North Carolina, and the
            United States, respectively, sitting in the State of North Carolina.
          </li>
          <li>
            Termination<br></br>If you breach any of the provisions of these
            Terms, all licenses granted by Bizflip will terminate automatically.
            Additionally, notwithstanding anything contained in these Terms, we
            reserve the right, with or without notice and in our sole
            discretion, to suspend, disable, terminate, or delete your Account
            and/or your ability to access or use the Service (or any part of the
            foregoing) at any time and for any or no reason, and you acknowledge
            and agree that we shall have no liability or obligation to you in
            such event and that you will not be entitled to a refund of any
            amounts that you have already paid to us.
          </li>
          <li>
            Severability<br></br>If any term, clause, or provision of these
            Terms is held invalid or unenforceable, then that term, clause, or
            provision will be severable from these Terms and will not affect the
            validity or enforceability of any remaining part of that term,
            clause, or provision, or any other term, clause, or provision of
            these Terms.
          </li>
          <li>
            Injunctive Relief<br></br>You agree that a breach of these Terms
            will cause irreparable injury to Bizflip for which monetary damages
            would not be an adequate remedy and Bizflip shall be entitled to
            equitable relief in addition to any remedies it may have hereunder
            or at law without a bond, other security, or proof of damages.
          </li>
          <li>
            California Residents<br></br>If you are a California resident, in
            accordance with Cal. Civ. Code § 1789.3, you may report complaints
            to the Complaint Assistance Unit of the Division of Consumer
            Services of the California Department of Consumer Affairs by
            contacting them in writing at 1625 North Market Blvd., Suite N 112
            Sacramento, CA 95834, or by telephone at (800) 952-5210.
          </li>
          <li>
            Export Laws<br></br>You agree that you will not export or re-export,
            directly or indirectly, the Service, and/or other information or
            materials provided by Bizflip hereunder, to any country for which
            the United States or any other relevant jurisdiction requires any
            export license or other governmental approval at the time of export
            without first obtaining such license or approval. In particular, but
            without limitation, the Service may not be exported or re-exported
            (a) into any U.S. embargoed countries or any country that has been
            designated by the U.S. Government as a “terrorist supporting”
            country, or (b) to anyone listed on any U.S. Government list of
            prohibited or restricted parties, including the U.S. Treasury
            Department’s list of Specially Designated Nationals or the U.S.
            Department of Commerce Denied Person’s List or Entity List. By using
            the Service, you represent and warrant that you are not located in
            any such country or on any such list. You are responsible for and
            hereby agree to comply at your sole expense with all applicable
            United States export laws and regulations.
          </li>
          <li>
            Survival<br></br>All sections which by their nature should survive
            the termination of these Terms shall continue in full force and
            effect subsequent to and notwithstanding any termination of these
            Terms by Bizflip or you. Termination will not limit any of Bizflip’s
            other rights or remedies at law or in equity.
          </li>
          <li>
            Miscellaneous<br></br>These Terms constitute the entire agreement
            between you and Bizflip relating to your access to and use of the
            Service. These Terms, and any rights and licenses granted hereunder,
            may not be transferred or assigned by you without the prior written
            consent of Bizflip, and Bizflip’s failure to assert any right or
            provision under these Terms shall not constitute a waiver of such
            right or provision. No waiver by either party of any breach or
            default hereunder shall be deemed to be a waiver of any preceding or
            subsequent breach or default. The section headings used herein are
            for reference only and shall not be read to have any legal effect.
          </li>
          <li>
            The Service is operated by us in the United States. Those who choose
            to access the Service from locations outside the United States do so
            at their own initiative and are responsible for compliance with
            applicable local laws. You and Bizflip agree that the United Nations
            Convention on Contracts for the International Sale of Goods will not
            apply to the interpretation or construction of these Terms.
          </li>
          <li>
            Except as otherwise provided herein, these Terms are intended solely
            for the benefit of the parties and are not intended to confer
            third-party beneficiary rights upon any other person or entity.
          </li>
        </ul>
        <p>
          bizflip.io, an online blockchain marketplace that allows third-party
          sellers to list an asset for sale; and to connect, communicate, and
          transact with buyers.<br></br>
          <br></br>bizflip.io and its affiliates (bizflip) provide website
          features and other products and services to you when you visit
          bizflip.io (the Website) and use products or services
          (collectively bizflip services). bizflip Services include the
          information, content, materials, products, and software included on or
          otherwise made available to you by Bizflip and references to the
          Bizflip Services in these Terms include any or all of these. bizflip
          provides the bizflip Services to you subject to these Terms and
          accessing the Website and using the Bizflip Services, you agree to be
          bound by them. Please read them carefully before using Bizflip
          Services.
        </p>
        <h3>1. Privacy</h3>
        <p>
          Please review our privacy & cookies tracking policies, which also
          govern your use of the bizflip Services, to understand our practices.
          bizflip will collect your personal information including your name,
          contact details, and payment details (including, without limitation,
          credit card details). All personal information will be handled, used,
          maintained, and disclosed by bizflip in accordance with all applicable
          privacy laws and data protection laws as well as our Privacy Policy
          which applies from time to time. Unless separately agreed between you
          and another buyer or seller, you must not use their personal
          information for any other purpose. You warrant to bizflip that you
          will comply with all privacy laws and data protection laws (including,
          without limitation, the Australian Privacy Act 1988 (Cth)) in relation
          to the storage, use, and transfer of personal information.
        </p>
        <h3>2. Electronic Communication</h3>
        <p>
          When you use the bizflip Services or send e-mails, text messages, or
          other communication from your desktop or mobile device to us, you are
          communicating with us electronically. We will communicate with you
          electronically in a variety of ways, such as by e-mail, text, or
          communication via our discussion forum. You consent to receive
          communications from us electronically and agree that communications
          that we provide to you electronically satisfy any legal requirement
          that such communication be in writing.
        </p>
        <h3>3. Copyright and Other Rights</h3>
        <p>
          All content included in or made available by Bizflip, such as text,
          graphics, logos, button icons, images, video and audio clips, digital
          downloads and data compilations is the property of Bizflip and is
          protected by Australian and international copyright and authors’
          rights laws and (where applicable) database right laws.<br></br>Other
          than to the extent necessary to use the Bizflip Services for their
          permitted purposes and in accordance with these Terms, you may not
          copy, extract and/or re-utilize any content of Bizflip without our
          express written consent, including, without limitation, any listings,
          descriptions, prices and account information. In particular, you may
          not utilize any data mining, robots, or similar data gathering and
          extraction tools to extract (whether once or many times) for
          re-utilization any substantial parts of the Bizflip Services or their
          content, without our express written consent. You may also not create
          and/or publish your own database that features substantial parts of
          the Bizflip Services or their content without our express written
          consent.
        </p>
        <h3>4. Trademarks</h3>
        <p>
          Graphics, logos, page headers, button icons, scripts, and service
          names included in or made available through the Bizflip Services are
          trademarks of Bizflip. Bizflip’s trademarks may not be used in
          connection with any product or service that is not Bizflip`s, in any
          manner that is likely to cause confusion among customers or in any
          manner that disparages or discredits Bizflip. All other trademarks not
          owned by Bizflip that appear in the Bizflip Services are the property
          of their respective owners, who may or may not be affiliated with,
          connected to, or sponsored by Bizflip. You may not frame or use
          framing techniques to enclose any trademark or logo (including images
          and text) of Bizflip without our express written consent. You may not
          use any meta tags or any other “hidden text” utilizing Bizflips’s
          names or trademarks without our express written consent.
        </p>
        <h3>5. Your Account</h3>
        <p>
          By registering/connecting and creating an account/wallet with Bizflip,
          you represent and warrant that you are over 18 years of age and you
          can form legally binding agreements under applicable law. If you are
          registering an account for a business, organization or other legal
          entity, you represent and warrant that you have the authority to
          legally bind that entity. Unless authorized by Bizflip in writing,
          <br></br> you may have only one account, unless authorized by Bizflip
          in writing. You are responsible for maintaining the confidentiality of
          your account and password. Except to the extent caused by our breach
          of these Terms, you are responsible for all activities that occur
          under your account regardless of whether the activities are authorized
          by you or undertaken by you. Bizflip is not responsible for
          unauthorized access to or use of your password or account. You should
          take all necessary steps to ensure that your password is kept
          confidential and secure and should inform us immediately if you have
          any reason to believe that your password has become known to anyone
          else, or if your password is being, or is likely to be used in an
          unauthorized manner. You must ensure that the details you provide to
          us are correct and complete and inform us of any changes. You can
          access and update certain information you have provided to us via your
          account dashboard.<br></br>
          If we have concerns with your account/wallet, or activity relating to
          your account/wallet, or if you are in breach of applicable laws or
          these Terms, we reserve the right to take action on your account in
          accordance with these Terms, including without limitation by (i)
          refusing service, (ii) suspending or restricting access to your
          account, (iii) terminating your account, or (iv) removing or editing
          content you post using your account. The action we elect to take and
          any notice you may receive will vary depending on the Bizflip
          Services, the circumstances, and our assessment of relevant factors.
        </p>
        <h3>6. ID Verification </h3>
        <p>
          Any reference to a buyer or seller being “verified” indicates only
          that the buyer or seller has completed a relevant verification or
          identification process and nothing else. Any such description is not
          an endorsement, certification or guarantee by Bizflip about any buyer
          or seller, including their identity, background or whether they are
          trustworthy, safe or suitable.<br></br>To access or use some of the
          Bizflip Services, you may be asked to provide proof of identity or
          other methods of identity verification. You agree to provide Bizflip
          or any third party which provides goods or services to Bizflip with
          all identification documents (including but not limited to, copies of
          passports and/or government-issued driver’s license) which Bizflip
          requests from you from time to time for the purposes of verifying your
          identity.
        </p>
        <h3>7. Bizflp’s Role</h3>
        <p>
          Bizflip allows third-party sellers to list and sell their assets using
          the Bizflip Services. Bizflip does not own, create, sell, resell,
          provide, control, offer or deliver listings or the assets contained
          therein. The relevant seller is indicated on the respective listing
          page. While Bizflip helps facilitate transactions that are carried out
          on the Website, Bizflip is not a buyer or seller in any transaction
          involving the seller’s assets or any other transaction among users of
          the Services.<br></br>  Bizflip provides an avenue for sellers and
          buyers to negotiate and complete transactions. Accordingly, the
          contract agreed to by the sellers and buyers regarding the seller’s
          assets is solely between the buyer and seller. Bizflip is not a party
          to this contract and does not assume any responsibility arising out of
          or in connection with the contract and/or the transaction. Bizflip
          does not act as an agent for the buyer or seller. The seller is solely
          responsible for the sale of their assets and the buyer is solely
          responsible for the purchase of the assets. <br></br>
          <br></br>
          By using the Services, buyer and seller acknowledge that in the event
          of a dispute between a buyer and seller, Bizflip is not a party to any
          transaction among the parties and therefore will not participate in
          any such dispute, except as follows. Buyer and seller acknowledge and
          agree that Bizflip may, upon receiving notice of a dispute from either
          party, and only prior to the shipping and/or delivery of the assets or
          under any circumstance where the following is not possible, choose in
          its sole and absolute discretion to cancel the transaction. In such an
          event buyer and seller acknowledge and agree that neither party shall
          have any cause of action against Bizflip and in the event either party
          should pursue litigation against Bizflip the non-litigating party
          shall hold Bizflip harmless in accordance with clause 22. 
          <br></br>Bizflip is not responsible for:
          <ul className={styles.listAlphabetic}>
            <li>
              the existence, quality, suitability of legality of a listed asset;
            </li>
            <li>
              the truth, accuracy or completeness of the information contained
              in a listing;
            </li>
            <li>the future performance of a listed asset;</li>
            <li>
              the performance or conduct of a buyer or seller or third-party on
              the Website; and/or
            </li>
            <li>
              the quality, suitability or ability of a third party which
              provides goods or services to Bizflip or to a buyer or seller.
            </li>
          </ul>
          <br></br>
          Your decision to purchase a listed asset shall be based solely on your
          own investigation and that of your legal, tax and other advisors. You
          accept sole responsibility for examining and investigating an asset
          and all information in a listing. This includes, but is not limited
          to, associated liabilities, financial statements, tax returns and any
          other facts or information which may impact your decision to purchase
          that listed asset and the price you are willing to pay. You understand
          that Bizflip may display only a summary description of a listed asset.
          <br></br>
          To help facilitate a safe, secure transaction and to preserve the
          integrity of the Bizflip Services, you can access legal services and
          template legal documents via BizflipLegal powered by Contracts
          Counsel. You acknowledge that you use these documents at your own
          risk. You agree that Bizflip nor Contracts Counsel has not in any way
          provided legal or financial advice by providing these documents or
          made representations or warranties that they are suitable for your
          requirements or needs and may not be appropriate or suitable to the
          sale or purchase of a particular asset. bizflip recommends all parties
          seek their own independent legal advice in relation to the documents.
        </p>
        <h3>8. Listing Rules</h3>
        <p>
          The following assets may be listed for sale on Bizflip:
          <ul className={styles.listAlphabetic}>
            <li>
              all detail and reference to files contained in a website or an
              application, including but not limited to, the HTML, the code
              base, other source code, logos, images, music, animation, films
              and other media, licenses and other works associated with the
              website or the application;
            </li>
            <li>
              any rights and obligations under or in relation to an agreement
              with a third party relating to assets which may include, but is
              not limited to hosting agreements, payment provider agreements,
              affiliate agreements and seller agreements;
            </li>
            <li>
              a brand name relating to a business, including any registered
              trademarks specified in the listing;
            </li>
            <li>any records;</li>
            <li>all rights to a domain name;</li>
            <li>
              any other documented asset including inventory or other agreement
              critical to the sale and ongoing concern of a business, including
              legal agreements, employment agreement, leasing agreements and
              other agreements; and
            </li>
            <li>
              any other particulars specified as being sold as part of a
              listing.
            </li>
          </ul>
          <br></br>
          For the avoidance of doubt, Bizflip does not promote, encourage or
          facilitate the sale of securities using Bizflip Services.
          <br></br>
          When you create a listing, you will be asked to provide complete and
          accurate information about your asset, including, but not limited to a
          business description and current and historical financial data. You
          are responsible for your listing and keeping your listing information
          up to date at all times. You will also be asked to set an asking price
          for your asset/s, either a set price in the case of a fixed-price
          listing or a starting price, reserve price, and optional buy it now
          price, in the case of an auction-format listing. Please be aware that
          Bizflip may, in its sole discretion, prevent you from listing your
          asset for sale, or suspend an existing listing, if it considers that a
          price set is unreasonable or not aligned with market value.
          <br></br>
          <ul className={styles.listAlphabetic}>
            <li>you own the asset or are entitled to sell the asset;</li>
            <li>
              you are the owner of all intellectual property rights, including
              but not limited to copyright, patent, trademark, designs whether
              registered or not and throughout the world and all other rights,
              title and interest in the asset,
            </li>
            <li>the asset is transferable to a buyer; and</li>
            <li>
              any and all agreements with third parties are transferable to the
              Buyer.
            </li>
          </ul>
          <br></br>
          You must not create a listing for an asset which is:
          <ul className={styles.listAlphabetic}>
            <li>
              is obscene, vulgar and/or deemed by Bizflip to be offensive;
            </li>
            <li>
              contains material which infringes the rights of a third party or
              which assists others to infringe the rights of a third party;
            </li>
            <li>
              is engaged in activities which contravenes law of any territory;
              or
            </li>
            <li>Bizflip deems inappropriate or in breach of these Terms.</li>
          </ul>
        </p>
        <h3>9. Listing Types </h3>
        <p>
          Bizflip enables two kinds of listings — auction-format listings and
          fixed-price listings.<br></br>With a fixed price-listing, any buyer
          can offer to purchase the asset contained in a listing. An offer must
          be placed on the listing. A seller can accept, reject or counter any
          offer. If you are a buyer and have placed an offer, you acknowledge
          and agree that should the seller accept that offer, you are committing
          to purchase the asset and may be required to pay for it. If you are a
          seller and have accepted an offer, you acknowledge and agree that you
          are committing to sell the listed asset to the buyer.<br></br>With an
          auction-format listing, a seller will accept bids from buyers for a
          predetermined amount of time — generally 30 days. For a bid to be
          valid, it must be higher than the starting price or then-current bid.
          At the end of the auction, the asset subject of the listing will be
          declared sold to the highest bidder, provided their bid exceeds the
          reserve price set by the seller. Should you win the auction, you agree
          that you are committing to purchase the listed asset and may be
          required to pay for it.
          <br></br>For the avoidance of doubt, Bizflip is not an auctioneer and
          a seller conducts an auction on their own behalf.<br></br>A listing
          may also show a ‘buy it now’ button with a price listed next to it. A
          buyer may purchase a listed asset simply by clicking the ‘buy it now’
          button, provided it is accepted by the seller. By clicking ‘buy it
          now,’ a buyer commits to buying the listed asset.
        </p>
        <h3>10. Exclusivity </h3>
        <p>
          If a listed asset has a set asking price, in the case of a
          fixed-priced listed, or a reserve price, in the case of an
          auction-format listing, equal to or above USD$25,000, you agree that
          you will promote and/or sell that asset exclusively using the Bizflip
          Services. For the period in which your listing is live on the Website
          and for 90 days from the date you remove your listing, you warrant
          that you will not use any other platform, marketplace or service,
          including a broker service, to promote or sell your asset, without the
          express written permission of bizflip.<br></br>If Bizflip discovers
          that you have been in violation of this clause 10, you acknowledge and
          agree that Bizflip has the right to immediately suspend your listing
          and terminate your account and may, at its discretion, take action
          against you to recover a success fee.
        </p>
        <h3>11. Marketplace Circumvention </h3>
        <p>
          You must conduct all inquiries into and discussions about a listed
          asset, through the Website unless expressly authorized by Bizflip. You
          must not circumvent Bizflip. If you make or accept payment for a
          listed asset, Bizflip is entitled to a success fee. If Bizflip is
          unable to recover its success fee from the seller, the buyer is liable
          to pay the success fee.
        </p>
        <h3>12. Our Fees</h3>
        <p>
          <ul className={styles.listAlphabetic}>
            <li>
              Listing Fees<br></br>You will be charged a listing fee when you
              list an asset for sale. For current fees, please see Bizflip
              pricing. You will only be charged a fee for creating a listing;
              there is no fee for editing a listing. You will be charged a fee
              whether or not the listed asset sells. Listing fees are
              non-refundable.<br></br>You may cancel your listing or account at
              any time and then following the specific instructions indicated to
              you in Bizflips response via support channels.
            </li>
            <li>
              Success Fees<br></br>If you successfully sell a listed asset to a
              buyer who becomes aware of your listing and/or connects or engages
              with you via Website, you agree to pay Bizflip a success fee which
              is calculated as a percentage of the total transaction value or
              final sale price paid by the buyer for your listed asset
              (including any applicable taxes). For current fees, please see
              Bizflip succession fee scale or email support at anytime. Our
              success fee is non-negotiable and non-refundable. Bizflip reserves
              the right to change the success fee at any time and will provide
              you with adequate notice of those changes before they become
              effective.
            </li>
          </ul>
        </p>
        <h3>13. Credits and Refunds</h3>
        <p>
          If Bizflip issues you a refund, that refund will be paid to you using
          the same payment method you used to pay Bizflip. Bizflip may refund an
          amount of paid by you if you are entitled to a refund in accordance
          with these Terms or Bizflip is required by law or considers that it is
          required by law to do so. Bizflip’s determination as to whether a
          refund is required is final and conclusive and may not be challenged
          by you.<br></br>
          You may earn points or credits for your activity on the Website. These
          credits are not equivalent to any currency. They may, however, be used
          to pay for Bizflip Services in accordance with rules specified by
          Bizflip from time to time. You cannot use credits to purchase a listed
          asset. The balance of any credits held by you may be reduced at any
          time by Bizflip for any amount owing by you to Bizflip on any account
          whatsoever including in relation to compensating Bizflip for any loss
          that it determines (in its sole and absolute discretion) it has
          suffered as a result of any breach of these Terms. The balance of
          credits held by you will expire 12 months from the date that you last
          used any credits. Balances of your credits that are not spent within
          this 12-month period are lost. You acknowledge and agree that Bizflip
          is not liable to you for any unauthorized withdrawals or unauthorized
          spending of your credits, including where such withdrawal or spend
          arises from any unauthorized use or access of your account.
        </p>
        <h3>14. Payment Processing</h3>
        <p>
          Bizflip is not an escrow service and does not hold property on behalf
          of any person. bizflip is not a payment provider. For the purposes of
          facilitating a transaction, any and all payment processing services
          through or in connection with your use of the Bizflip Services are
          provided to you by one or more independent third-party service
          provider, as appropriate. You hereby consent and authorize Bizflip to
          share any information and payment instructions you provide with any
          third-party service provider(s).
        </p>
        <h3>15. Valuation Calculator</h3>
        <p>
          You are solely responsible for setting a price for your listed asset.
          To help you determine an appropriate price, Bizflip makes a valuation
          calculator. You acknowledge and agree that any valuation produced by
          the Bizflip Valuation Calculator is an estimate only and must not be
          construed as or relied upon as a professional valuation. The valuation
          is generated by a mathematical model in reliance on available data,
          without an in-depth analysis of the asset and without having regard to
          market conditions or features which may affect the value and
          saleability of the asset.
        </p>
        <h3>16. Due Diligence</h3>
        <p>
          Bizflip provides due diligence services and you can pay to acquire a
          due diligence report on a listed asset. While Bizfip strives to make
          the information in this report as accurate as possible, we make no
          claims, promises or guarantees about the accuracy, completeness or
          adequacy of the contents of this report, and expressly disclaim
          liability for errors and omissions in its contents. No warranty of any
          kind, implied, expressed or statutory including but not limited to the
          warranties of non-infringement of third party rights, title,
          merchantability of fitness for a particular purpose is given with
          respect to the contents in this report.<br></br>Furthermore, any
          information provided herein with regard to the listed asset is
          informational in nature. We are not a legal advisory or business
          advisory service and do not purport to tell or suggest which business
          decisions to make. You understand and acknowledge that there is a risk
          involved in the purchase of a website or online asset. We assume no
          responsibility or liability for your investment or business results.
          Factual statements within this report are made as of the data stated
          and are subject to change without notice.
          <br></br>All information is provided solely for educational purposes
          and you are encouraged to seek independent advice from a competent
          professional person if legal, financial, tax, or other expert
          assistance is required.
        </p>
        <h3>17. Reviews, comments and communications</h3>
        <p>
          You may post reviews, comments, and other content; send
          communications; and submit questions or other material, as long as the
          content is not:<br></br>
          <ul className={styles.listAlphabetic}>
            <li>of a personal or solicitous nature,</li>
            <li>unrelated to a listing,</li>
            <li>an attempt to transact outside the Website,</li>
            <li>
              illegal, obscene, abusive, threatening, defamatory or an invasion
              of privacy,
            </li>
            <li>infringing of intellectual property rights, or,</li>
            <li>injurious to third parties or objectionable.</li>
          </ul>
          <br></br>You must not post or send any content that contains software
          viruses, or that comprises political campaigning, commercial
          solicitation, chain letters, mass mailings or any form of “spam”. You
          may not use a false e-mail address, impersonate any person or entity,
          or otherwise mislead as to the origin of any such content or
          communication. We reserve the right (but not the obligation) to remove
          or edit any content at any time and for any reason in its sole and
          absolute discretion.<br></br>While comments and reviews are visible to
          other users, interactions and exchanges via the discussion forum are
          private as between individuals and accessible to Bizflip.
        </p>
        <h3>18. Confidential Information</h3>
        <p>
          In order to list an asset for sale on Bizflip, you will be asked to
          provide certain non-public, proprietary information to Bizflip
          relating to the asset/s you wish to list for sale including, but not
          limited to (a) information expressly marked or disclosed as
          confidential by you; (b) financial, traffic, user and/or other
          business information; (c) any and all details relating to your asset
          or business, including the URL, if relevant; (d) your profit and loss
          or balance sheet; and (e) any other information related to the listed
          asset (Your Confidential Information).<br></br>Your Confidential
          Information will at all times be, and will at all times remain, your
          property and all applicable rights, including, but not limited to, all
          intellectual property rights, right of publicity, or other personal or
          proprietary rights embodied in the Confidential Information will
          remain with you.<br></br>Bizflip agrees that it will treat Your
          Confidential Information as confidential and not disclose Your
          Confidential Information until (a) you make it publicly available to
          registered users and account holders by way of setting it live as a
          public listing or (b) in the case of a “CONFIDENTIAL” listing, you
          make it publicly available to a registered user or account holder
          approved by you and who has signed the integrated NDA relating to your
          listing. Bizflip may disclose the Seller Confidential Information to
          its employees, agents, attorneys, accountants, officers, and directors
          (“Representatives”) in connection Bizflip’s services or (b) in the
          event that Bizflip is required to disclose any Confidential
          Information, and, when possible pursuant to statutory or regulatory
          authority, Bizflip will provide you with prompt written notice so that
          you may seek a protective order or waive compliance by Bizflip with
          these Terms. If, in the absence of a protective order or the receipt
          of a waiver hereunder, Bizflip is nonetheless, on the advice of its
          counsel, legally required to disclose your Confidential Information,
          Bizflip may disclose such information without liability hereunder.
        </p>
        <h3>18. Confidential Information</h3>
        <p>
          In order to list an asset for sale on Bizflip, you will be asked to
          provide certain non-public, proprietary information to Bizflip
          relating to the asset/s you wish to list for sale including, but not
          limited to (a) information expressly marked or disclosed as
          confidential by you; (b) financial, traffic, user and/or other
          business information; (c) any and all details relating to your asset
          or business, including the URL, if relevant; (d) your profit and loss
          or balance sheet; and (e) any other information related to the listed
          asset (Your Confidential Information).<br></br>Your Confidential
          Information will at all times be, and will at all times remain, your
          property and all applicable rights, including, but not limited to, all
          intellectual property rights, right of publicity, or other personal or
          proprietary rights embodied in the Confidential Information will
          remain with you.<br></br>Bizflip agrees that it will treat Your
          Confidential Information as confidential and not disclose Your
          Confidential Information until (a) you make it publicly available to
          registered users and account holders by way of setting it live as a
          public listing or (b) in the case of a “CONFIDENTIAL” listing, you
          make it publicly available to a registered user or account holder
          approved by you and who has signed the integrated NDA relating to your
          listing. Bizflip may disclose the Seller Confidential Information to
          its employees, agents, attorneys, accountants, officers, and directors
          (“Representatives”) in connection Bizflip’s services or (b) in the
          event that Bizflip is required to disclose any Confidential
          Information, and, when possible pursuant to statutory or regulatory
          authority, Bizflip will provide you with prompt written notice so that
          you may seek a protective order or waive compliance by Bizflip with
          these Terms. If, in the absence of a protective order or the receipt
          of a waiver hereunder, Bizflip is nonetheless, on the advice of its
          counsel, legally required to disclose your Confidential Information,
          Bizflip may disclose such information without liability hereunder.
        </p>
        <h3>19. Intellectual Property Rights </h3>
        <p>
          You are responsible for your content and you represent and warrant
          that you own or otherwise control all of the rights to the content and
          material that you post and that, as at the date that the content or
          material is posted it: (i) is accurate; (ii) complies with these Terms
          and (iii) does not breach any applicable laws. If you post content or
          submit material, and unless we indicate otherwise, you grant Bizflip a
          non-exclusive, royalty-free and fully sublicensable and transferable
          rights to use, reproduce, modify, adapt, publish, translate, create
          derivative works from, distribute, and display such content throughout
          the world in any media;<br></br>You agree that the rights you grant
          above are irrevocable during the entire period of protection of your
          intellectual property rights associated with such content and
          material. To the extent permitted by law, as applicable, you: (i)
          consent to any infringement of; and (ii) agree to waive, any right you
          have to be identified as the author of such content and any right you
          have to object to derogatory treatment of such content. You agree to
          perform all further acts necessary to perfect any of the above rights
          granted by you to Bizflip, at our request.<br></br>
          You agree to indemnify Bizflip for all claims brought by a third party
          against Bizflip arising out of or in connection with the content and
          material you supply except to the extent that any liability arises
          from our failure to remove the content or material.
        </p>
        <h3>20. Third Party Links</h3>
        <p>
          The Website may contain links to third-party websites or resources
          that we don’t own or control (for example, links to Facebook, Twitter,
          and Pinterest or Google Analytics). When you access these third-party
          services, you do so at your own risk. The third parties may require
          you to accept their own terms of use and privacy policies. Bizflip is
          not a party to those agreements — they are solely between you and the
          third party.
        </p>
        <h3>21. Prohibited Activities </h3>
        <p>
          You may not use the Bizflip Services:<br></br>
          <ul className={styles.listAlphabetic}>
            <li>
              in any way that causes, or is likely to cause, any Bizflip
              Service, or any access to it to be interrupted, damaged or
              impaired in any way
            </li>
            <li>
              in any way that may interfere with or harm any other user of the
              Bizflip Services;
            </li>
            <li>
              for fraudulent purposes, or in connection with a criminal or other
              unlawful activity,
            </li>
            <li>in any manner that is not permitted under these Terms.</li>
          </ul>
        </p>
        <h3>22. Disclaimer & Liability </h3>
        <p>
          Unless otherwise specified in writing, Bizflip disclaims, and does not
          make, any representation or warranty of any kind in respect of the
          Bizflip Services including without limitation any representation or
          warranty,<br></br>
          <ul className={styles.listAlphabetic}>
            <li>that they are free of viruses or other harmful components;</li>
            <li>
              that your use of the Bizflip Services will be uninterrupted or
              error-free; or
            </li>
            <li>
              as to the suitability or availability of the Bizflip Services.
            </li>
          </ul>
          <br></br>
          Bizflip will not be responsible for:
          <ul className={styles.listAlphabetic}>
            <li>
              losses arising from the unavailability of, or your inability to
              use the Bizflip Services
            </li>
            <li>
              losses that are not directly caused by any breach on our part;
            </li>
            <li>
              any business loss, loss of sales, profits, revenue, contracts,
              anticipated savings, data, goodwill or wasted expenditure;
            </li>
            <li>any indirect or consequential losses;</li>
            <li>
              any delay or failure to comply with our obligations under these
              conditions if the delay or failure arises from any cause which is
              beyond our reasonable control.
            </li>
          </ul>
          <br></br>
          For any other loss relating to the Bizflip Services, we limit our
          liability to the amount you have paid to us for the relevant Bizflip
          Services.
          <br></br>Nothing in these conditions is intended to:<br></br>
          <ul className={styles.listAlphabetic}>
            <li>
              override any express commitments Bizflip gives to you with respect
              to the Bizflip Services (for example, the provision of a refund in
              certain circumstances) or
            </li>
            <li>
              exclude, restrict or modify any right or remedy you have in
              statute including under the Australian Consumer Law or otherwise
              to the extent that that right or remedy cannot be excluded,
              restricted or modified under law. Any disclaimer, exclusion, or
              limitation in these conditions applies as provided for in these
              conditions to the full extent permitted by law and subject to any
              such non-excludable right or remedy.
            </li>
          </ul>
        </p>
        <h3>23. Indemnity </h3>
        <p>
          You agree to indemnify and hold Bizflip and its affiliates and their
          officers, directors, employees, and agents harmless from any and all
          claims, demands, losses, liabilities, and expenses (including legal
          fees) arising out of or in connection with:<br></br>
          <ul className={styles.listAlphabetic}>
            <li>
              your use of the Website, Bizflip Services or assets obtained as a
              result;
            </li>
            <li>your breach or violation of any of these Terms;</li>
            <li>Bizflip’s use of your content;</li>
            <li>
              your violation of the rights of any third party, including another
              seller or buyer.
            </li>
          </ul>
          <br></br>You agree to hold Bizflip, its principals, officers,
          directors, brokers, agents, servants, employees and assigns harmless
          from any misrepresentations made by you.
        </p>
        <h3>24. Applicable Law </h3>
        <p>
          The laws of North Carolina, United States of America govern these
          Terms and any dispute of any sort that might arise between the
          parties. Any dispute relating in any way to these Terms will only be
          adjudicated in the courts of North Carolina. Each party consents to
          exclusive jurisdiction and venue in these courts. Notwithstanding the
          foregoing, either party may seek injunctive relief in any state,
          federal, or national court of competent jurisdiction for any actual or
          alleged infringement of such party’s, its affiliates’ or any third
          party’s intellectual property or other proprietary rights. The United
          Nations Convention on Contracts for the International Sale of Goods,
          and any local laws implementing the Convention on Contracts for the
          International Sale of Goods, do not apply to this Agreement.
        </p>
        <h3>25. Force Majeure</h3>
        <p>
          You agree that Bizflip will not be liable or responsible for any
          failure in, or delay to, the provision of the Bizflip Services or in
          Bizflip complying with these Terms, where such failure or delay has
          arisen or is anticipated to arise as a direct or indirect result of:
          <ul className={styles.listAlphabetic}>
            <li>
              fire, earthquake, storm, flood, hurricane, inclement weather or
              other act of God, war, terrorism, explosion, sabotage, industrial
              accident or an industrial strike;
            </li>
            <li>
              denial of service attacks, telecommunications failure, hardware
              failure or the failure of software provided by a third party to
              function in accordance with its specifications;
            </li>
            <li>
              a significant demand is placed on Bizflip Services which is above
              the usual level of demand and which results in a failure of
              Bizflip’s software and hardware to function correctly;
            </li>
            <li>
              the failure of any third party (including without limitation, any
              bank or other financial organization) to fulfill any obligations
              to Bizflip; or
            </li>
            <li>
              any other circumstances or events that are beyond the reasonable
              control of Bizflip (as the case may be).
            </li>
          </ul>
        </p>
        <h3>26. Notices</h3>
        <p>
          Bizflip may give notice by means of a general notice on the Website,
          electronic mail to the email address on your account, telephone or
          text message to any phone number provided in connection with your
          account, or by written communication sent by mail or pre-paid post to
          any address connected with your account. Such notice shall be deemed
          to have been given upon the expiration of 48 hours after mailing or
          posting (if sent by mail or pre-paid post) or 12 hours after sending
          (if sent by email or telephone). You may give notice to Bizflip, with
          such notice deemed given when received by Bizflip, at any time by mail
          or pre-paid post to our registered agent for service of process, c/o
          Bizflip(the smile guys inc)
        </p>
        <h3>27. Assignment </h3>
        <p>
          Bizflip may assign its rights and novate or transfer obligations that
          arise under these Terms. You must not assign, novate or otherwise
          transfer your rights or obligations under these Terms without the
          prior written consent of Bizflip (which may be withheld).
        </p>
        <h3>28. Waiver </h3>
        <p>
          A provision of or a right created under these Terms may not be waived
          except in writing signed by the party or parties to be bound by the
          waiver. No single or partial exercise by any party of any right, power
          or remedy will preclude any other or further exercise of that or any
          other right, power or remedy. The rights, powers or remedies in these
          Terms are cumulative with and not exclusive of any rights, powers or
          remedies provided independently.
        </p>
        <h3>29. Entire Agreement</h3>
        <p>
          These Terms supersedes all prior representations, arrangements,
          understandings, and agreements between the parties relating to the
          subject matter and sets forth the entire and exclusive agreement and
          understanding between the parties.
        </p>
        <h3> 30. Amendments </h3>
        <p>
          We reserve the right to make changes to our website, these Terms, our
          policies, and our listings at any time by posting the changes on our
          website. Your continued access to or use of the Website and Bizflip
          Services will constitute acceptance of the revised Terms.
        </p>
        <h3>31. Severability </h3>
        <p>
          If any of these Terms are judged invalid or unenforceable for any
          reason whatsoever by a court of competent jurisdiction, such
          invalidity or unenforceability (unless deletion of such provision
          would materially adversely affect one of the parties) will not affect
          the operation or interpretation of any other term to the intent that
          the invalid or unenforceable term will be treated as severed from the
          Terms.
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
