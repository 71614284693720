const data = [
  {
    name: 'DeGod #477',
    url: 'https://metadata.degods.com/g/476-dead.png',
  },
  {
    name: 'DeGod #578',
    url:
      'https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://metadata.degods.com/g/578-dead.png',
  },

  {
    name: 'DeGod #3474',
    url:
      'https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://metadata.degods.com/g/3474-dead.png',
  },
  {
    name: 'Board Ape - #1146',
    url:
      'https://i.seadn.io/gae/c_Q3RSFYxq_mBn6xRxAMZ7ByQDRsokyui7WOncKqklQd4AbzcDlXbZzNihFmGxpYEmvhEg5YUpZ8Fb6_bnxnBmpshocVjxRmfyjkh7E?auto=format&w=512',
  },
  {
    name: '#100',
    url:
      'https://i.seadn.io/gae/cQdJa-b13yPX_t2myOP-yTWsnivLyw2TjOebOuEmScctikEcnVJLbvUXtrb10bUdNDtYf3PKIA7zjNVXYWNdRAhDiByu3ukE0l-gkQ?auto=format&w=512',
  },
  {
    name: '3047',
    url:
      'https://i.seadn.io/gcs/files/c1d278fe80b429823c38dee3babafb9f.png?w=500&auto=format',
  },
  {
    name: '459',
    url:
      'https://i.seadn.io/gae/-tibTrEXBCbLNfUxVsvqpsgLMB-mYtywZSywY1oQrFF-obqwn1JJPyNqUBWrBR2IwFuWBVxu2O0YYJVdz3Jk4FZ_pYVn0zYjccSOfQ?w=500&auto=format',
  },

  {
    name: 'DeGod #578',
    url:
      'https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://metadata.degods.com/g/578-dead.png',
  },
];
export default data;
