import React from 'react';

const IconHeart = props => (
  <svg
    width="19"
    height="17"
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.8717 2.32611C16.4514 1.90569 15.9525 1.57219 15.4034 1.34465C14.8542 1.11711 14.2656 1 13.6712 1C13.0768 1 12.4882 1.11711 11.9391 1.34465C11.3899 1.57219 10.891 1.90569 10.4708 2.32611L9.59867 3.19821L8.72656 2.32611C7.87775 1.4773 6.72652 1.00044 5.52612 1.00044C4.32572 1.00044 3.17448 1.4773 2.32567 2.32611C1.47686 3.17492 1 4.32616 1 5.52656C1 6.72696 1.47686 7.87819 2.32567 8.727L3.19777 9.5991L9.59867 16L15.9996 9.5991L16.8717 8.727C17.2921 8.30679 17.6256 7.80785 17.8531 7.25871C18.0807 6.70957 18.1978 6.12097 18.1978 5.52656C18.1978 4.93214 18.0807 4.34355 17.8531 3.7944C17.6256 3.24526 17.2921 2.74633 16.8717 2.32611V2.32611Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconHeart;
